div.question{
  margin-top: 30px;
  max-width: 490px;
  margin: 30px auto;
}
div.question div.question-item{
  margin-bottom: 20px;
  line-height: 1.4em;
}
div.question div.label{
  font-size: .8em;
  padding-bottom: 5px;
  font-weight: bold;
}
